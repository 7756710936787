//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-832:_4928,_4256,_7936,_3484,_3376,_3372,_1308,_5692;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-832')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-832', "_4928,_4256,_7936,_3484,_3376,_3372,_1308,_5692");
        }
      }catch (ex) {
        console.error(ex);
      }